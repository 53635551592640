/** Reset Styles **/
@import 'reset';
@import 'src/assets/constants/color';
@import 'mixins';
/** helpers **/
@import 'helpers';

* {
  box-sizing: border-box;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.hidden {
  display: none;
}

.stakeForm .mantine-TextInput-input {
  text-align: right;
  font-weight: 700;
  font-size: 26px;
}

.main-select {
  & .mantine-Select-wrapper {
    position: relative;
    padding: 8px 20px 5px;
    left: -20px;
    border-radius: 8px;

    & input{
      position: relative;
      z-index: 1;
      background-color: transparent;
      border: none;
      font-family: Space Grotesk, sans-serif;
      font-weight: 700;
      font-size: 64px;
      letter-spacing: -6.5%;
      padding: 0 16px 0 0;
      margin: 0;
    }
  }

  & .mantine-Select-dropdown {
    transform: translateX(-20px);
  }
}

.main-group .mantine-Select-rightSection {
  margin: 0 20px 0 0;
}

.select-option-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.container {
  padding: 18px 10%;
  background: linear-gradient(180deg, $c1 0%, $c2 47.4%), $c3;
  min-height: 100%;
}

@include onTablet {
  .container {
    padding: 30px 10%;
  }
}