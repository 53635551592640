@use 'sass:math';
@import 'variables';

$grid-breakpoints: (
        $small '(max-width: #{$grid-medium - 1})',
        $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
        $medium-down '(max-width: #{$grid-large - 1})',
        $medium-up '(min-width: #{$grid-medium})',
        $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
        $large-down '(max-width: #{$grid-widescreen - 1})',
        $large-up '(min-width: #{$grid-large})',
        $large-xl-up '(min-width: #{$grid-xl-large})',
        $widescreen '(min-width: #{$grid-widescreen})',
        $ios5 '(min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2)', /* iPhone 5 */
        $ios6 '(min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2)', /* iPhone 6, 6s, 7, 8 */
        $ios6plus '(min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)', /* iPhone 6+, 6s+, 7+, 8+ */
        $iosX '(min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3)', /* iPhone X , XS, 11 Pro  */
        $iosXR '(min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2)', /* iPhone XR, 11  */
        $iosMax '(min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3)', /* iPhone XS Max, 11 Pro Max  */
);
@mixin media-query($media-query) {
  $breakpoint-found: false;
  @each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);
    @if $media-query == $name and $declaration {
      $breakpoint-found: true;
      @media only screen and #{$declaration} {
        @content;
      }
    }
  }
  @if $breakpoint-found == false {
    @warn 'Breakpoint "#{$media-query}" does not exist';
  }
}
@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal, $font-style: normal, $font-weight: normal) {
  font-size: $font-size * 1px;
  @if $font-style==normal {
    font-style: normal;
  } @else {
    font-family: $basic-font, sans-serif;
  }
  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }
  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }

  @if $font-weight==normal {
    font-weight: normal;
  } @else {
    font-weight: $font-weight;
  }
}
@mixin shadow($shadow: $shadow) {
  box-shadow: $shadow;
}
@mixin flex {
  display: flex;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
}
@mixin flex-row($align:flex-start, $justify:flex-start) {
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $justify;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}
@mixin flex-center-between($align:true) {
  display: flex;
  justify-content: space-between;
  @if ($align == true) {
    align-items: center;
  }
}
@mixin transition($type:all, $time: .3s, $animation: ease) {
  transition: $type $time $animation;
}
@mixin z-index($index) {
  z-index: z-index($index);
}
@mixin text-ellipsis(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@function rem($pxValue) {
  @return math.div($pxValue, $html-font-size) + rem;
}

@mixin onTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1280px ) {
    @content;
  }
}